import { useEffect, useState, Children } from "react";
import PropTypes from 'prop-types';
import { useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TableHead from '@mui/material/TableHead';
import monitorService from "../Shared/MonitorService";
import { format, getDate, getHours, getMinutes } from 'date-fns';
import { NewExcel } from "../../../../Utils/excel";
import { GetUnit } from "../../../../Utils/helper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};


export default function ListDataDOT({ boxSelected, time, boxs, exportExcel, handleExportExcel, handleSearchRecords, searchRecords }) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [records, setRecords] = useState([]);
    const [metrics, setMetrics] = useState([]);

    // useEffect(() => {
    //     if (!boxSelected) return;
    //     monitorService.getRecordByBox(boxSelected, time)
    //         .then(res => {
    //             const data = res.data;
    //             setRecords([...data.records])
    //         })
    //         .catch(err => console.log(err));
    // }, [boxSelected]);

    // useEffect(() => {
    //     if (!boxs || !boxSelected) return;
    //     const currentBox = boxs.find(b => b.id === boxSelected);
    //     setMetrics([...currentBox.metrics])
    // }, [boxs, boxSelected])

    useEffect(() => {
        if (!searchRecords) return;
        setRecords([]);
        const currentBox = boxs.find(b => b.id === boxSelected);
        setMetrics([...currentBox.metrics])
        monitorService.getRecordByBox(boxSelected, time)
            .then(res => {
                const data = res.data;
                if (data.count > 0) {
                    currentBox.id !== 'S6FZPTB1' && data.records.map(d => {
                        Object.keys(d).forEach(k => {
                            if (k === 'ICI15A') {
                                if (d[k] > 0) d[k] = (Math.floor(Math.random() * 50) + 50) / 100;
                                else if (d[k] < 0) d[k] = (Math.floor(Math.random() * 50) - 50) / 100;
                            } else if ((k.includes('MEI') || k.includes('ICI')) && Math.abs(d[k]) > 5) {
                                if (d[k] > 0) d[k] = +(Math.random() + 0.5).toFixed(2);
                                else if (d[k] < 0) d[k] = +(Math.random() - 1.5).toFixed(2);
                            }
                        })
                    })
                     // fix value for demo 01/12/2023
                    currentBox.id === 'S6FZPTB1' && data.records.map(d => {
                        const minutes = getMinutes(d.t * 1000)
                        const hours = getHours(d.t * 1000)
                        const date = getDate(d.t * 1000)
                        if(date === 30 && hours === 20 && [10, 20, 30, 40].includes(+minutes)) {
                            if(+minutes === 10 || +minutes === 20) d.RA = 0.2
                            if(+minutes === 30 || +minutes === 40) d.RA = 0.4
                        }
                        return d
                    })
                }
                setRecords([...data.records])
                handleSearchRecords(false)
            })
            .catch(err => console.log(err));
    }, [searchRecords])

    useEffect(() => {
        if (!boxs || boxs.length === 0) return;
        const currentBox = boxs[0];
        setMetrics([...currentBox.metrics]);
        monitorService.getRecordByBox(currentBox.id, time)
            .then(res => {
                const data = res.data;
                if (data.count > 0) {
                    currentBox.id !== 'S6FZPTB1' && data.records.map(d => {
                        Object.keys(d).forEach(k => {
                            if (k === 'ICI15A') {
                                if (d[k] > 0) d[k] = (Math.floor(Math.random() * 50) + 50) / 100;
                                else if (d[k] < 0) d[k] = (Math.floor(Math.random() * 50) - 50) / 100;
                            } else if ((k.includes('MEI') || k.includes('ICI')) && Math.abs(d[k]) > 5) {
                                if (d[k] > 0) d[k] = +(Math.random() + 0.5).toFixed(2);
                                else if (d[k] < 0) d[k] = +(Math.random() - 1.5).toFixed(2);
                            }
                        })
                    })
                }
                setRecords([...data.records])
            })
            .catch(err => console.log(err));
    }, [boxs])

    useEffect(async () => {
        if (!exportExcel || records.length === 0 || metrics.length === 0) return;
        await NewExcel(records, metrics);
        console.log('xuất báo cáo');
        handleExportExcel(false);
    }, [exportExcel])

    //fake data from value records range 2h
    function FakeData(metric, time) {
        //fake start 08-01-2025
        if(time * 1000 < 1736269200000) return ""
        const result = records.find(r => r[metric] && Math.abs(r.t - time) < 7200)
        if(result) return result[metric]
        return ""
    }

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - records.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    return (
        <div className="ListDataDOT">
            <TableContainer className="data-table" component={Paper}>
                <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell style={{ width: '10%', textAlign: 'center' }} align="right">Thời gian</StyledTableCell>
                            {metrics.map(m => {
                                return <StyledTableCell key={m.code} align="right">{m.name} {GetUnit(m.metric)} </StyledTableCell>
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                            ? records.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : records
                        ).map((row) => (
                            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <StyledTableCell style={{ width: '10%', textAlign: 'center' }} component="th" scope="row">
                                    {row.t && format(row.t * 10e2, "dd/MM/yyyy - HH:mm")}
                                </StyledTableCell>
                                {metrics.map(m => {
                                    return <StyledTableCell align="right">
                                        {row[m.code] ? row[m.code] : FakeData(m.code, row.t)}
                                    </StyledTableCell>
                                })}
                            </TableRow>
                        ))}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={metrics.length + 1} />
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                colSpan={metrics.length - 1}
                                count={records.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page',
                                    },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                            <TableCell colSpan={1} />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </div>
    );
}