export const InterpolationSource = [
    // Sông Lũy
    {
        group_id: "KKKXW2TW",
        V: {
            "110": [0.00, 0.01, 0.02, 0.02, 0.03, 0.04, 0.05, 0.05, 0.06, 0.07],
            "111": [0.08, 0.09, 0.09, 0.10, 0.11, 0.12, 0.12, 0.13, 0.14, 0.15],
            "112": [0.16, 0.16, 0.17, 0.18, 0.19, 0.19, 0.20, 0.21, 0.22, 0.22],
            "113": [0.23, 0.24, 0.25, 0.26, 0.26, 0.27, 0.28, 0.29, 0.29, 0.30],
            "114": [0.31, 0.34, 0.36, 0.39, 0.41, 0.44, 0.46, 0.49, 0.51, 0.54],
            "115": [0.56, 0.63, 0.70, 0.77, 0.84, 0.91, 0.97, 1.04, 1.11, 1.18],
            "116": [1.25, 1.37, 1.48, 1.60, 1.72, 1.84, 1.95, 2.07, 2.19, 2.30],
            "117": [2.42, 2.59, 2.76, 2.92, 3.09, 3.26, 3.43, 3.60, 3.76, 3.93],
            "118": [4.10, 4.32, 4.55, 4.77, 5.00, 5.22, 5.44, 5.67, 5.89, 6.12],
            "119": [6.34, 6.67, 7.01, 7.34, 7.68, 8.01, 8.34, 8.68, 9.01, 9.35],
            "120": [9.68, 10.14, 10.59, 11.05, 11.50, 11.96, 12.42, 12.87, 13.33, 13.78],
            "121": [14.24, 14.79, 15.34, 15.89, 16.44, 16.99, 17.54, 18.09, 18.64, 19.19],
            "122": [19.74, 20.39, 21.04, 21.69, 22.34, 22.99, 23.63, 24.28, 24.93, 25.58],
            "123": [26.23, 26.99, 27.76, 28.52, 29.29, 30.05, 30.81, 31.58, 32.34, 33.11],
            "124": [33.87, 34.78, 35.68, 36.59, 37.49, 38.40, 39.31, 40.21, 41.12, 42.02],
            "125": [42.93, 43.98, 45.04, 46.09, 47.15, 48.20, 49.25, 50.31, 51.36, 52.42],
            "126": [53.47, 54.65, 55.84, 57.02, 58.20, 59.39, 60.57, 61.75, 62.93, 64.12],
            "127": [65.30, 66.60, 67.90, 69.20, 70.50, 71.80, 73.10, 74.40, 75.70, 77.00],
            "128": [78.30, 79.71, 81.12, 82.53, 83.94, 85.35, 86.76, 88.17, 89.58, 90.99],
            "129": [92.40, 93.91, 95.41, 96.92, 98.42, 99.93, 101.43, 102.94, 104.44, 105.95],
            "130": [107.45, 109.06, 110.67, 112.28, 113.89, 115.50, 117.11, 118.72, 120.33, 121.94],
            "131": [123.55, 125.27, 127.00, 128.72, 130.44, 132.17, 133.89, 135.61, 137.33, 139.06],
            "132": [140.78, 142.62, 144.47, 146.31, 148.16, 150.00, 151.84, 153.69, 155.53, 157.38],
            "133": [159.22, 161.18, 163.15, 165.11, 167.08, 169.04, 171.00, 172.97, 174.93, 176.90],
            "134": [178.86, 180.95, 183.04, 185.13, 187.22, 189.31, 191.39, 193.48, 195.57, 197.66],
            "135": [199.75, 201.96, 204.16, 206.37, 208.58, 210.79, 212.99, 215.20, 217.41, 219.61],
            "136": [221.82, 224.19, 226.57, 228.94, 231.32, 233.69, 236.06, 238.44, 240.81, 243.19],
            "137": [245.56, 248.05, 250.54, 253.04, 255.53, 258.02, 260.51, 263.00, 265.50, 267.99],
            "138": [270.48, 270.48, 270.48, 270.48, 270.48, 270.48, 270.48, 270.48, 270.48, 270.48]
        },
        D: {
            z: [118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132],
            dr: [0, 0.2, 0.4, 0.6, 0.8, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.2, 3.4, 3.6, 3.8, 4, 4.2, 4.4],
            value: [
                [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                [5.3, 5.8, 6.2, 6.7, 7.1, 7.5, 7.8, 8.2, 8.5, 8.9, 9.2, 9.5, 9.8, 10, 10.3],
                [10.4, 11.4, 12.4, 13.3, 14.1, 14.9, 15.6, 16.3, 17, 17.6, 18.3, 18.9, 19.5, 20, 20.6],
                [15.4, 17, 18.4, 19.8, 21, 22.2, 23.3, 24.4, 25.4, 26.4, 27.3, 28.2, 29.1, 30, 30.8],
                [20.4, 22.5, 24.4, 26.2, 27.9, 29.4, 30.9, 32.3, 33.7, 35, 36.3, 37.5, 38.7, 39.8, 41],
                [25.2, 27.8, 30.3, 32.5, 34.6, 36.6, 38.5, 40.3, 42, 43.6, 45.2, 46.7, 48.2, 49.7, 51.1],
                [29.9, 33.1, 36, 38.8, 41.3, 43.7, 45.9, 48.1, 50.2, 52.2, 54.1, 55.9, 57.7, 59.4, 61.1],
                [34.5, 38.3, 41.7, 44.9, 47.9, 50.7, 53.3, 55.9, 58.3, 60.6, 62.9, 65, 67.1, 69.1, 71.1],
                [38.9, 43.3, 47.3, 51, 54.4, 57.6, 60.7, 63.6, 66.3, 69, 71.6, 74.1, 76.5, 78.8, 81],
                [43.3, 48.3, 52.8, 56.9, 60.8, 64.5, 67.9, 71.2, 74.3, 77.3, 80.2, 83, 85.7, 88.4, 90.9],
                [47.5, 53.1, 58.2, 62.8, 67.2, 71.2, 75.1, 78.8, 82.3, 85.6, 88.8, 92, 95, 97.9, 100.7],
                [51.6, 57.8, 63.4, 68.6, 73.4, 77.9, 82.2, 86.2, 90.1, 93.8, 97.4, 100.8, 104.2, 107.4, 110.5],
                [55.5, 62.4, 68.6, 74.3, 79.6, 84.5, 89.2, 93.6, 97.9, 101.9, 105.8, 109.6, 113.3, 116.8, 120.2],
                [59.4, 66.9, 73.7, 79.9, 85.7, 91, 96.1, 101, 105.6, 110, 114.3, 118.4, 122.3, 126.1, 129.9],
                [63.1, 71.3, 78.7, 85.4, 91.6, 97.5, 103, 108.2, 113.2, 118, 122.6, 127, 131.3, 135.4, 139.5],
                [66.6, 75.6, 83.5, 90.8, 97.5, 103.8, 109.8, 115.4, 120.8, 125.9, 130.9, 135.6, 140.2, 144.7, 149],
                [70.1, 79.7, 88.3, 96.1, 103.3, 110.1, 116.5, 122.5, 128.3, 133.8, 139.1, 144.2, 149.1, 153.9, 158.5],
                [73.3, 83.7, 92.9, 101.3, 109.1, 116.3, 123.1, 129.5, 135.7, 141.6, 147.2, 152.6, 157.9, 163, 167.9],
                [76.5, 87.6, 97.5, 106.4, 114.7, 122.4, 129.6, 136.5, 143, 149.3, 155.3, 161.1, 166.6, 172, 177.3],
                [79.4, 91.4, 101.9, 111.4, 120.2, 128.4, 136.1, 143.4, 150.3, 156.9, 163.3, 169.4, 175.3, 181, 186.6],
                [82.3, 95, 106.2, 116.3, 125.6, 134.3, 142.5, 150.2, 157.5, 164.5, 171.2, 177.7, 183.9, 190, 195.8],
                [84.9, 98.5, 110.4, 121.1, 131, 140.2, 148.8, 156.9, 164.6, 172, 179.1, 185.9, 192.5, 198.8, 205],
                [87.4, 101.8, 114.4, 125.8, 136.2, 145.9, 155, 163.5, 171.7, 179.4, 186.9, 194.1, 201, 207.6, 214.1]
            ]
        },
        S: {
            z: [121, 121.5, 122, 122.5, 123, 123.5, 124, 124.5, 125, 125.5, 126, 126.5, 127, 127.5, 128, 128.5, 129, 129.5, 130, 130.5, 131, 131.5],
            dr: [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5],
            value1: [
                [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                [5, 9, 12, 14, 16, 18, 19, 21, 22, 23, 24, 25, 27, 28, 29, 30, 31, 31, 32, 33, 34, 35],
                [5, 15, 21, 26, 30, 34, 37, 40, 43, 45, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 67, 69],
                [5, 16, 28, 36, 42, 48, 53, 58, 62, 66, 70, 73, 76, 80, 83, 86, 89, 92, 94, 97, 100, 102],
                [5, 16, 30, 43, 52, 60, 67, 74, 80, 85, 90, 95, 100, 104, 108, 113, 116, 120, 124, 128, 131, 134],
                [5, 16, 37, 46, 59, 70, 80, 88, 96, 103, 110, 116, 122, 127, 133, 138, 143, 148, 153, 157, 162, 166],
                [5, 16, 37, 46, 64, 78, 90, 101, 110, 119, 128, 135, 143, 150, 156, 163, 169, 175, 180, 186, 191, 197],
                [5, 16, 37, 46, 64, 83, 98, 112, 123, 134, 144, 153, 162, 171, 178, 186, 193, 200, 207, 214, 220, 226],
                [5, 16, 37, 46, 64, 85, 104, 120, 134, 147, 159, 170, 180, 190, 199, 208, 217, 225, 233, 241, 248, 255],
                [5, 16, 37, 46, 64, 85, 107, 127, 144, 159, 173, 185, 197, 209, 219, 229, 239, 249, 258, 266, 275, 283],
                [5, 16, 37, 46, 64, 85, 107, 130, 150, 168, 184, 199, 213, 226, 238, 249, 260, 271, 281, 291, 301, 310],
                [5, 16, 37, 46, 64, 85, 107, 131, 155, 175, 194, 211, 226, 241, 255, 268, 280, 292, 304, 315, 326, 336],
                [5, 16, 37, 46, 64, 85, 107, 131, 156, 180, 202, 221, 239, 255, 271, 285, 299, 313, 325, 338, 349, 361],
                [5, 16, 37, 46, 64, 85, 107, 131, 139, 180, 202, 221, 241, 269, 298, 329, 360, 392, 425, 459, 494, 530]
            ],
            value2: [
                [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                [11, 18, 24, 28, 32, 35, 38, 41, 44, 46, 49, 51, 53, 55, 57, 59, 61, 63, 65, 66, 68, 70],
                [11, 30, 43, 52, 60, 67, 74, 80, 85, 90, 95, 100, 104, 108, 113, 116, 120, 124, 128, 131, 134, 138],
                [11, 32, 55, 71, 84, 96, 106, 106, 124, 132, 139, 146, 153, 159, 166, 172, 178, 183, 189, 194, 199, 204],
                [11, 32, 60, 85, 104, 120, 134, 147, 159, 170, 180, 190, 199, 208, 217, 225, 233, 241, 248, 255, 262, 269],
                [11, 32, 60, 92, 119, 141, 159, 176, 192, 206, 219, 232, 244, 255, 266, 276, 286, 296, 305, 314, 323, 332],
                [11, 32, 60, 92, 128, 156, 180, 202, 221, 239, 255, 271, 285, 299, 313, 325, 338, 349, 361, 372, 383, 393],
                [11, 32, 60, 92, 129, 166, 197, 223, 247, 268, 288, 307, 324, 341, 357, 372, 387, 401, 414, 428, 440, 453],
                [11, 32, 60, 92, 129, 170, 208, 241, 269, 295, 318, 340, 361, 380, 399, 417, 434, 450, 466, 481, 496, 510],
                [11, 32, 60, 92, 129, 166, 214, 253, 287, 317, 345, 371, 395, 417, 438, 459, 478, 497, 515, 533, 550, 566],
                [11, 32, 60, 92, 129, 166, 214, 260, 301, 336, 368, 398, 425, 451, 475, 499, 521, 542, 563, 582, 601, 620],
                [11, 32, 60, 92, 129, 166, 214, 262, 309, 351, 388, 422, 453, 482, 510, 536, 561, 585, 608, 630, 651, 672],
                [11, 32, 60, 92, 129, 166, 214, 262, 313, 361, 403, 442, 477, 510, 541, 571, 598, 625, 651, 675, 699, 722],
                [11, 32, 60, 92, 129, 166, 214, 262, 277, 325, 375, 427, 482, 538, 597, 658, 720, 785, 851, 919, 989, 1060]
            ],
            value3: [
                [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                [16, 28, 36, 42, 48, 53, 58, 62, 66, 70, 73, 76, 80, 83, 86, 89, 92, 94, 97, 100, 102, 105],
                [16, 45, 64, 78, 90, 101, 110, 119, 128, 135, 143, 150, 156, 163, 169, 175, 180, 186, 191, 197, 202, 207],
                [16, 48, 83, 107, 127, 144, 159, 173, 185, 197, 209, 219, 229, 239, 249, 258, 266, 275, 283, 291, 299, 306],
                [16, 48, 90, 128, 156, 180, 202, 221, 239, 255, 271, 285, 299, 313, 325, 338, 349, 361, 372, 383, 393, 403],
                [16, 48, 90, 138, 178, 211, 239, 264, 288, 309, 329, 348, 365, 382, 399, 414, 429, 444, 458, 472, 485, 498],
                [16, 48, 90, 138, 191, 234, 271, 303, 331, 358, 383, 406, 428, 449, 469, 488, 506, 524, 541, 558, 574, 590],
                [16, 48, 90, 138, 193, 250, 295, 335, 370, 403, 432, 460, 487, 512, 535, 558, 580, 601, 622, 641, 660, 679],
                [16, 48, 90, 138, 193, 255, 313, 361, 403, 442, 477, 510, 541, 571, 598, 625, 651, 675, 699, 722, 744, 766],
                [16, 48, 90, 138, 193, 255, 321, 380, 431, 476, 518, 556, 592, 626, 658, 688, 718, 746, 773, 799, 825, 849],
                [16, 48, 90, 138, 193, 255, 321, 391, 451, 504, 552, 597, 638, 677, 713, 748, 781, 813, 844, 874, 902, 930],
                [16, 48, 90, 138, 193, 255, 321, 392, 464, 526, 582, 633, 679, 723, 765, 804, 841, 877, 912, 945, 977, 1008],
                [16, 48, 90, 138, 193, 255, 321, 392, 469, 541, 605, 663, 716, 766, 812, 856, 898, 938, 976, 1013, 1048, 1083],
                [16, 48, 90, 138, 193, 255, 321, 392, 469, 541, 605, 663, 722, 807, 895, 986, 1080, 1177, 1276, 1378, 1483, 1590]
            ]
        },
        ZD: 120.5 // Cao trình đáy tràn sông lũy
    },

    // Hồ Dương Đông
    {
        group_id: 'WFYOUGL1',
        V: {
            z: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40],
            value: [0.000, 0.000, 0.002, 0.008, 0.025, 0.063, 0.131, 0.238, 0.388, 0.577, 0.802, 1.062, 1.355, 1.679, 2.040, 2.436, 2.859, 3.313, 3.796, 4.306, 4.841, 5.397, 5.973, 6.567, 7.178, 7.807, 8.453, 9.116, 9.796, 10.493, 11.208, 11.942, 12.695, 13.468, 14.261]
        },
        D: {
            z: [0, 14.60, 16.00, 18.00, 20.00, 22.00, 24.00, 26.00, 28.77, 30.00, 31.10],
            dr: [0, 0.15, 0.2, 0.3, 0.45, 0.6],
            value: [
                [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0.06, 0.1, 0.14, 0.16, 0.19, 0.21, 0.23, 0.25, 0.26, 0.27],
                [0, 0.1, 0.16, 0.22, 0.26, 0.3, 0.33, 0.36, 0.4, 0.42, 0.43],
                [0, 0.21, 0.33, 0.45, 0.55, 0.63, 0.7, 0.76, 0.84, 0.88, 0.9],
                [0, 0.36, 0.57, 0.78, 0.94, 1.08, 1.2, 1.31, 1.45, 1.51, 1.56],
                [0, 0.45, 0.71, 0.97, 1.17, 1.34, 1.49, 1.63, 1.81, 1.88, 1.94]
            ]
        },
        S: {
            z: [0, 25, 25.5, 26, 26.5, 27, 27.5, 28, 28.5, 28.77, 29, 29.5, 30],
            dr: [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.47, 5, 5.5],
            value2: [
                [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 9.81, 14.27, 17.65, 20.49, 22.99, 25.25, 27.33, 29.27, 30.26, 31.08, 32.8, 34.44],
                [0, 9.81, 24.09, 31.62, 37.72, 42.99, 47.7, 52.01, 56, 58.05, 59.73, 63.26, 66.6],
                [0, 9.81, 24.09, 41.62, 51.56, 59.95, 67.35, 74.06, 80.24, 83.39, 86, 91.42, 96.55],
                [0, 9.81, 24.09, 41.62, 61.79, 73.78, 84.16, 93.47, 101.99, 106.33, 109.9, 117.31, 124.31],
                [0, 9.81, 24.09, 41.62, 61.79, 84.28, 98.05, 110.22, 121.26, 126.85, 131.45, 140.96, 149.93],
                [0, 9.81, 24.09, 41.62, 61.79, 84.28, 108.86, 124.22, 138.01, 144.96, 150.64, 162.38, 173.4],
                [0, 9.81, 24.09, 41.62, 61.79, 84.28, 108.86, 135.37, 152.19, 160.6, 167.46, 181.56, 194.75],
                [0, 9.81, 24.09, 41.62, 61.79, 84.28, 108.86, 135.37, 163.69, 173.71, 181.84, 198.46, 213.97],
                [0, 9.81, 24.09, 41.62, 61.79, 84.28, 108.86, 135.37, 163.69, 183.63, 193.08, 212.3, 230.08],
                [0, 9.81, 24.09, 41.62, 61.79, 84.28, 108.86, 135.37, 163.69, 183.63, 193.08, 225.39, 245.93],
                [0, 9.81, 24.09, 41.62, 61.79, 84.28, 108.86, 135.37, 163.69, 183.63, 193.08, 225.39, 258.6]
            ]
        }
    },

    //  Hồ Cây Mến
    {
        group_id: 'IXC8QY8W',
        V: {
            z: [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
            value: [0, 0.05, 0.38, 1.4, 4.2, 10.22, 20.02, 33.36, 49.86, 69.23, 91.14, 115.42, 142.08, 171.07, 202.49, 236.35, 272.58, 311.2, 352.27, 395.79, 441.8, 490.29, 541.18, 594.47]
        },
        Free: {
            z: [21.10, 21.15, 21.20, 21.25, 21.45, 21.50, 21.90, 21.95, 22.00, 22.05, 22.10, 22.15, 22.20, 22.25, 22.30],
            value: [0.00, 0.71, 2.02, 3.71, 13.21, 16.14, 45.64, 49.99, 54.46, 59.06, 63.78, 68.63, 73.59, 78.66, 83.85]
        }
    },
    // Khe Giữa
    {
        group_id: 'EHFJZIUT',
        V: {
            z: [20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55],
            value: [0.0000, 0.0009, 0.0051, 0.0159, 0.0373, 0.0709, 0.1175, 0.1777, 0.2539, 0.3487, 0.4689, 0.6178, 0.7916, 0.9901, 1.2137, 1.4661, 1.7501, 2.0664, 2.4191, 2.8098, 3.2412, 3.7187, 4.2425, 4.8098, 5.422, 6.0822, 6.7917, 7.5527, 8.3664, 9.2325, 10.1604, 11.155, 12.2078, 13.3169, 14.485, 15.71845]
        },
        D: {
            z: [32.5, 33, 33.5, 34, 34.5, 35, 35.5, 36, 36.5, 37, 37.50, 38.00, 38.50, 39.00, 39.50, 40.00, 40.50, 41.00, 41.50, 42.00, 42.50, 43.00, 43.50, 44.00, 44.50, 45.00, 45.50, 46.00, 46.50, 47.00, 47.50, 48.00, 48.50, 48.75, 49.25, 49.50, 49.75, 49.81],
            dr: [0, 0.043, 0.085, 0.128, 0.170, 0.213, 0.255, 0.298, 0.340, 0.383, 0.425, 0.468, 0.510, 0.553, 0.595, 0.638, 0.680, 0.723, 0.765, 0.808, 0.850],
            value: [
                [0, 0.287, 0.436, 0.586, 0.838, 0.991, 1.184, 1.328, 1.495, 1.627, 1.774, 1.883, 1.997, 2.096, 2.198, 2.279, 2.361, 2.428, 2.494, 2.545, 2.593],
                [0, 0.315, 0.478, 0.643, 0.919, 1.086, 1.298, 1.455, 1.638, 1.783, 1.944, 2.064, 2.189, 2.297, 2.408, 2.498, 2.588, 2.661, 2.733, 2.789, 2.842],
                [0, 0.34, 0.517, 0.694, 0.992, 1.174, 1.402, 1.572, 1.77, 1.926, 2.1, 2.23, 2.365, 2.481, 2.602, 2.699, 2.796, 2.875, 2.953, 3.013, 3.071],
                [0, 0.363, 0.552, 0.743, 1.061, 1.255, 1.499, 1.681, 1.892, 2.06, 2.246, 2.384, 2.529, 2.653, 2.782, 2.886, 2.989, 3.074, 3.157, 3.222, 3.283],
                [0, 0.386, 0.586, 0.788, 1.126, 1.331, 1.59, 1.783, 2.007, 2.185, 2.383, 2.529, 2.682, 2.815, 2.952, 3.061, 3.171, 3.261, 3.349, 3.418, 3.483],
                [0, 0.406, 0.618, 0.83, 1.187, 1.404, 1.677, 1.88, 2.116, 2.303, 2.512, 2.666, 2.828, 2.967, 3.112, 3.227, 3.343, 3.438, 3.531, 3.603, 3.672],
                [0, 0.426, 0.648, 0.871, 1.245, 1.472, 1.759, 1.972, 2.22, 2.416, 2.635, 2.797, 2.966, 3.112, 3.264, 3.385, 3.507, 3.606, 3.704, 3.779, 3.852],
                [0, 0.445, 0.677, 0.91, 1.3, 1.538, 1.837, 2.06, 2.319, 2.524, 2.752, 2.921, 3.098, 3.251, 3.409, 3.536, 3.663, 3.767, 3.869, 3.948, 4.023],
                [0, 0.464, 0.704, 0.947, 1.354, 1.601, 1.912, 2.144, 2.413, 2.627, 2.865, 3.041, 3.225, 3.384, 3.549, 3.68, 3.813, 3.921, 4.027, 4.109, 4.188],
                [0, 0.481, 0.731, 0.983, 1.405, 1.661, 1.984, 2.225, 2.505, 2.726, 2.973, 3.156, 3.347, 3.512, 3.683, 3.82, 3.957, 4.069, 4.179, 4.265, 4.346],
                [0, 0.498, 0.757, 1.017, 1.454, 1.72, 2.054, 2.303, 2.593, 2.822, 3.078, 3.267, 3.465, 3.636, 3.812, 3.954, 4.096, 4.212, 4.326, 4.415, 4.499],
                [0, 0.514, 0.782, 1.051, 1.502, 1.776, 2.122, 2.379, 2.678, 2.915, 3.179, 3.374, 3.579, 3.755, 3.938, 4.084, 4.231, 4.351, 4.468, 4.56, 4.647],
                [0, 0.53, 0.806, 1.083, 1.548, 1.831, 2.187, 2.452, 2.761, 3.005, 3.277, 3.478, 3.689, 3.871, 4.059, 4.21, 4.361, 4.485, 4.606, 4.7, 4.79],
                [0, 0.546, 0.829, 1.115, 1.593, 1.884, 2.25, 2.523, 2.841, 3.092, 3.372, 3.579, 3.796, 3.983, 4.177, 4.332, 4.488, 4.615, 4.74, 4.837, 4.929],
                [0, 0.561, 0.852, 1.145, 1.637, 1.936, 2.312, 2.593, 2.919, 3.177, 3.464, 3.677, 3.9, 4.092, 4.291, 4.451, 4.611, 4.742, 4.87, 4.969, 5.064],
                [0, 0.575, 0.874, 1.175, 1.679, 1.986, 2.372, 2.66, 2.995, 3.259, 3.554, 3.773, 4.002, 4.199, 4.403, 4.567, 4.731, 4.865, 4.996, 5.099, 5.196],
                [0, 0.589, 0.896, 1.204, 1.721, 2.035, 2.431, 2.726, 3.069, 3.34, 3.642, 3.866, 4.101, 4.303, 4.512, 4.679, 4.848, 4.985, 5.12, 5.225, 5.325],
                [0, 0.603, 0.917, 1.233, 1.762, 2.083, 2.488, 2.79, 3.141, 3.419, 3.728, 3.957, 4.197, 4.404, 4.618, 4.79, 4.962, 5.103, 5.24, 5.348, 5.45],
                [0, 0.617, 0.937, 1.26, 1.801, 2.13, 2.544, 2.853, 3.212, 3.496, 3.812, 4.046, 4.292, 4.503, 4.722, 4.897, 5.074, 5.217, 5.358, 5.468, 5.573],
                [0, 0.63, 0.958, 1.287, 1.84, 2.176, 2.599, 2.914, 3.281, 3.571, 3.894, 4.134, 4.384, 4.6, 4.824, 5.003, 5.183, 5.33, 5.474, 5.586, 5.693],
                [0, 0.643, 0.977, 1.314, 1.878, 2.221, 2.653, 2.974, 3.348, 3.645, 3.974, 4.219, 4.474, 4.695, 4.923, 5.106, 5.29, 5.44, 5.587, 5.701, 5.81],
                [0, 0.656, 0.997, 1.34, 1.915, 2.265, 2.705, 3.033, 3.415, 3.717, 4.053, 4.302, 4.563, 4.788, 5.021, 5.207, 5.395, 5.548, 5.697, 5.814, 5.925],
                [0, 0.668, 1.016, 1.366, 1.952, 2.308, 2.757, 3.091, 3.48, 3.788, 4.13, 4.385, 4.65, 4.879, 5.117, 5.307, 5.498, 5.653, 5.806, 5.925, 6.038],
                [0, 0.681, 1.034, 1.391, 1.988, 2.35, 2.808, 3.148, 3.544, 3.857, 4.206, 4.465, 4.736, 4.969, 5.211, 5.404, 5.599, 5.757, 5.913, 6.034, 6.149],
                [0, 0.693, 1.053, 1.415, 2.023, 2.392, 2.857, 3.204, 3.607, 3.926, 4.281, 4.544, 4.819, 5.057, 5.303, 5.5, 5.698, 5.859, 6.017, 6.141, 6.258],
                [0, 0.705, 1.071, 1.44, 2.057, 2.433, 2.906, 3.258, 3.668, 3.993, 4.354, 4.622, 4.902, 5.144, 5.394, 5.594, 5.795, 5.959, 6.12, 6.246, 6.365],
                [0, 0.716, 1.088, 1.463, 2.091, 2.473, 2.954, 3.312, 3.729, 4.059, 4.426, 4.698, 4.983, 5.229, 5.483, 5.687, 5.891, 6.058, 6.222, 6.349, 6.47],
                [0, 0.728, 1.106, 1.487, 2.125, 2.513, 3.002, 3.365, 3.789, 4.124, 4.497, 4.774, 5.063, 5.312, 5.571, 5.778, 5.986, 6.155, 6.321, 6.451, 6.574],
                [0, 0.739, 1.123, 1.51, 2.158, 2.552, 3.048, 3.418, 3.847, 4.188, 4.567, 4.848, 5.141, 5.395, 5.657, 5.867, 6.079, 6.25, 6.419, 6.551, 6.676],
                [0, 0.75, 1.14, 1.533, 2.19, 2.59, 3.094, 3.469, 3.905, 4.251, 4.635, 4.921, 5.219, 5.476, 5.742, 5.956, 6.17, 6.345, 6.516, 6.65, 6.776],
                [0, 0.761, 1.157, 1.555, 2.222, 2.628, 3.139, 3.52, 3.962, 4.313, 4.703, 4.993, 5.295, 5.556, 5.826, 6.043, 6.26, 6.437, 6.611, 6.747, 6.875],
                [0, 0.772, 1.173, 1.577, 2.254, 2.665, 3.184, 3.57, 4.019, 4.374, 4.77, 5.063, 5.37, 5.635, 5.909, 6.128, 6.349, 6.529, 6.705, 6.842, 6.973],
                [0, 0.783, 1.189, 1.599, 2.285, 2.702, 3.228, 3.619, 4.074, 4.435, 4.836, 5.133, 5.444, 5.713, 5.99, 6.213, 6.437, 6.619, 6.798, 6.937, 7.069],
                [0, 0.788, 1.197, 1.61, 2.3, 2.72, 3.249, 3.643, 4.102, 4.464, 4.868, 5.168, 5.481, 5.751, 6.031, 6.255, 6.48, 6.663, 6.843, 6.984, 7.117],
                [0, 0.798, 1.213, 1.631, 2.331, 2.756, 3.293, 3.692, 4.156, 4.524, 4.933, 5.236, 5.554, 5.827, 6.111, 6.338, 6.566, 6.752, 6.934, 7.076, 7.211],
                [0, 0.809, 1.229, 1.652, 2.361, 2.792, 3.335, 3.739, 4.21, 4.582, 4.997, 5.304, 5.625, 5.903, 6.19, 6.42, 6.651, 6.839, 7.024, 7.168, 7.304],
                [0, 0.81, 1.231, 1.654, 2.365, 2.796, 3.34, 3.745, 4.216, 4.589, 5.004, 5.312, 5.634, 5.912, 6.199, 6.429, 6.661, 6.849, 7.034, 7.178, 7.315]
            ]
        },
        S: {
            z: [44, 44.25, 44.5, 44.75, 45, 45.25, 45.5, 45.75, 46, 46.25, 46.5, 46.75, 47, 47.25, 47.5, 47.75, 48, 48.25, 48.5, 48.75, 49, 49.25, 49.5, 49.75, 49.81, 50.06, 50.23],
            dr: [0.00, 0.25, 0.50, 0.75, 1.00, 1.25, 1.50, 1.75, 2.00, 2.25, 2.50, 2.75, 3.00, 3.25, 3.50, 3.80],
            value1: [
                [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0.000, 1.428, 1.428, 1.428, 1.428, 1.428, 1.428, 1.428, 1.428, 1.428, 1.428, 1.428, 1.428, 1.428, 1.428, 1.428],
                [0.000, 2.696, 4.039, 4.039, 4.039, 4.039, 4.039, 4.039, 4.039, 4.039, 4.039, 4.039, 4.039, 4.039, 4.039, 4.039],
                [0.000, 3.535, 6.102, 7.42, 7.42, 7.42, 7.42, 7.42, 7.42, 7.42, 7.42, 7.42, 7.42, 7.42, 7.42, 7.42],
                [0.000, 4.21, 7.625, 10.106, 11.424, 11.424, 11.424, 11.424, 11.424, 11.424, 11.424, 11.424, 11.424, 11.424, 11.424, 11.424],
                [0.000, 4.791, 8.892, 12.214, 14.635, 15.966, 15.966, 15.966, 15.966, 15.966, 15.966, 15.966, 15.966, 15.966, 15.966, 15.966],
                [0.000, 5.309, 9.999, 14.009, 17.258, 19.638, 20.988, 20.988, 20.988, 20.988, 20.988, 20.988, 20.988, 20.988, 20.988, 20.988],
                [0.000, 5.78, 10.995, 15.598, 19.532, 22.724, 25.075, 26.448, 26.448, 26.448, 26.448, 26.448, 26.448, 26.448, 26.448, 26.448],
                [0.000, 6.216, 11.908, 17.04, 21.568, 25.438, 28.583, 30.914, 32.313, 32.313, 32.313, 32.313, 32.313, 32.313, 32.313, 32.313],
                [0.000, 6.624, 12.756, 18.369, 23.427, 27.889, 31.705, 34.813, 37.132, 38.557, 38.557, 38.557, 38.557, 38.557, 38.557, 38.557],
                [0.000, 7.007, 13.551, 19.608, 25.15, 30.142, 34.547, 38.316, 41.394, 43.706, 45.159, 45.159, 45.159, 45.159, 45.159, 45.159],
                [0.000, 7.371, 14.302, 20.774, 26.761, 32.238, 37.171, 41.526, 45.256, 48.309, 50.618, 52.099, 52.099, 52.099, 52.099, 52.099],
                [0.000, 7.717, 15.016, 21.877, 28.281, 34.205, 39.623, 44.504, 48.813, 52.51, 55.544, 57.854, 59.363, 59.363, 59.363, 59.363],
                [0.000, 8.049, 15.697, 22.928, 29.724, 36.065, 41.931, 47.295, 52.129, 56.399, 60.067, 63.085, 65.398, 66.936, 66.936, 66.936],
                [0.000, 8.368, 16.35, 23.932, 31.099, 37.834, 44.119, 49.93, 55.246, 60.037, 64.272, 67.915, 70.922, 73.24, 74.806, 74.806],
                [0.000, 8.674, 16.977, 24.896, 32.416, 39.524, 46.203, 52.433, 58.196, 63.466, 68.219, 72.424, 76.045, 79.043, 81.368, 83.187],
                [0.000, 8.971, 17.582, 25.824, 33.682, 41.145, 48.197, 54.822, 61.003, 66.72, 71.949, 76.667, 80.845, 84.448, 87.439, 90.156],
                [0.000, 9.258, 18.167, 26.719, 34.902, 42.704, 50.112, 57.111, 63.687, 69.822, 75.496, 80.688, 85.375, 89.528, 93.116, 96.624],
                [0.000, 9.536, 18.734, 27.586, 36.081, 44.208, 51.956, 59.312, 66.262, 72.792, 78.883, 84.518, 89.676, 94.334, 98.465, 102.686],
                [0.000, 9.806, 19.284, 28.426, 37.222, 45.663, 53.737, 61.434, 68.741, 75.645, 82.13, 88.182, 93.78, 98.907, 103.539, 108.409],
                [0.000, 10.069, 19.819, 29.242, 38.329, 47.072, 55.461, 63.485, 71.134, 78.395, 85.254, 91.699, 97.713, 103.278, 108.375, 113.845],
                [0.000, 10.326, 20.34, 30.036, 39.406, 48.441, 57.133, 65.472, 73.448, 81.051, 88.268, 95.086, 101.492, 107.471, 113.005, 119.032],
                [0.000, 10.576, 20.848, 30.809, 40.453, 49.772, 58.757, 67.4, 75.692, 83.623, 91.182, 98.357, 105.137, 111.506, 117.452, 124.003],
                [0.000, 10.82, 21.344, 31.564, 41.474, 51.068, 60.338, 69.275, 77.872, 86.118, 94.005, 101.523, 108.659, 115.401, 121.737, 128.782],
                [0.000, 10.878, 21.461, 31.742, 41.716, 51.375, 60.711, 69.717, 78.386, 86.706, 94.671, 102.268, 109.487, 116.316, 122.743, 129.903],
                [0.000, 11.116, 21.943, 32.475, 42.707, 52.631, 62.242, 71.531, 80.492, 89.115, 97.393, 105.316, 112.873, 120.055, 126.849, 134.473],
                [0.000, 11.275, 22.265, 32.964, 43.368, 53.469, 63.262, 72.739, 81.893, 90.717, 99.202, 107.339, 115.119, 122.532, 129.567, 137.494]
            ]
        }
    },
    // Am Chúa
    {
        group_id: '52ZVBWMR',
        V: {
            z: [23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38],
            value: [0, 0.03, 0.12, 0.25, 0.44, 0.67, 0.95, 1.29, 1.68, 2.12, 2.61, 3.15, 3.74, 4.41, 5.18, 6.02]
        },
        D: {
            z: [24, 24.5, 25, 25.5, 26, 26.5, 27, 27.5, 28, 28.5, 29, 29.5, 30, 30.5, 31, 31.5, 32, 32.5, 33, 33.5, 34, 34.5, 35, 35.5],
            dr: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.1, 1.2, 1.25],
            value: [
                [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0.15, 0.18, 0.21, 0.23, 0.26, 0.28, 0.3, 0.31, 0.33, 0.35, 0.36, 0.38, 0.39, 0.41, 0.42, 0.43, 0.45, 0.46, 0.47, 0.48, 0.49, 0.5, 0.51, 0.53],
                [0.28, 0.35, 0.41, 0.46, 0.51, 0.55, 0.59, 0.62, 0.66, 0.69, 0.72, 0.75, 0.78, 0.81, 0.84, 0.86, 0.89, 0.91, 0.94, 0.96, 0.98, 1.01, 1.03, 1.05],
                [0.41, 0.52, 0.61, 0.68, 0.75, 0.82, 0.88, 0.93, 0.98, 1.03, 1.08, 1.13, 1.17, 1.21, 1.25, 1.29, 1.33, 1.37, 1.4, 1.44, 1.47, 1.5, 1.54, 1.57],
                [0.53, 0.68, 0.8, 0.9, 1, 1.08, 1.16, 1.23, 1.3, 1.37, 1.43, 1.49, 1.55, 1.61, 1.66, 1.72, 1.77, 1.82, 1.86, 1.91, 1.96, 2, 2.05, 2.09],
                [0.64, 0.83, 0.98, 1.12, 1.23, 1.34, 1.44, 1.53, 1.62, 1.71, 1.78, 1.86, 1.93, 2, 2.07, 2.14, 2.2, 2.26, 2.32, 2.38, 2.44, 2.5, 2.55, 2.6],
                [0.75, 0.98, 1.16, 1.32, 1.47, 1.6, 1.72, 1.83, 1.94, 2.04, 2.13, 2.22, 2.31, 2.4, 2.48, 2.56, 2.63, 2.71, 2.78, 2.85, 2.92, 2.99, 3.05, 3.12],
                [0.84, 1.12, 1.34, 1.53, 1.7, 1.85, 1.99, 2.12, 2.25, 2.36, 2.48, 2.58, 2.69, 2.79, 2.88, 2.97, 3.06, 3.15, 3.24, 3.32, 3.4, 3.48, 3.56, 3.63],
                [0.92, 1.25, 1.51, 1.73, 1.92, 2.1, 2.26, 2.41, 2.55, 2.69, 2.82, 2.94, 3.06, 3.17, 3.28, 3.39, 3.49, 3.59, 3.69, 3.78, 3.88, 3.97, 4.06, 4.14],
                [0.99, 1.37, 1.67, 1.92, 2.14, 2.34, 2.52, 2.7, 2.86, 3.01, 3.16, 3.3, 3.43, 3.56, 3.68, 3.8, 3.92, 4.03, 4.14, 4.25, 4.35, 4.45, 4.55, 4.65],
                [1.05, 1.49, 1.82, 2.11, 2.35, 2.58, 2.78, 2.98, 3.16, 3.33, 3.49, 3.65, 3.79, 3.94, 4.08, 4.21, 4.34, 4.47, 4.59, 4.71, 4.82, 4.94, 5.05, 5.16],
                [1.1, 1.6, 1.97, 2.29, 2.56, 2.81, 3.04, 3.25, 3.45, 3.64, 3.82, 3.99, 4.16, 4.32, 4.47, 4.62, 4.76, 4.9, 5.03, 5.16, 5.29, 5.42, 5.54, 5.66],
                [1.13, 1.69, 2.11, 2.46, 2.77, 3.04, 3.29, 3.53, 3.75, 3.95, 4.15, 4.34, 4.52, 4.69, 4.86, 5.02, 5.18, 5.33, 5.48, 5.62, 5.76, 5.9, 6.03, 6.16],
                [1.14, 1.74, 2.18, 2.55, 2.87, 3.15, 3.42, 3.66, 3.89, 4.11, 4.31, 4.51, 4.7, 4.88, 5.05, 5.22, 5.38, 5.54, 5.7, 5.85, 5.99, 6.14, 6.28, 6.41]
            ]
        },
        S: {
            z: [30, 30.5, 31, 31.5, 32, 32.5, 33, 33.5, 34, 34.5, 35, 35.5, 36, 36.5, 37],
            dr: [0, 0.2, 0.4, 0.6, 0.8, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.2, 3.4, 3.6, 3.8, 4, 4.2, 4.4, 4.6, 4.8, 5, 5.2, 5.4, 5.6, 5.8, 6],
            value1: [
                [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                [1.4, 2.1, 2.6, 3.1, 3.4, 3.8, 4.1, 4.4, 4.6, 4.9, 5.1, 5.4, 5.6, 5.8, 6],
                [3.1, 4, 5.1, 5.9, 6.7, 7.4, 8, 8.6, 9.2, 9.7, 10.2, 10.7, 11.1, 11.6, 12],
                [3.1, 6, 7, 9, 10, 11, 12, 13, 14, 14, 15, 16, 17, 17, 18],
                [3.1, 7, 9, 11, 13, 14, 16, 17, 18, 19, 20, 21, 22, 23, 24],
                [3.1, 9, 11, 14, 16, 18, 19, 21, 22, 23, 25, 26, 27, 28, 29],
                [3.1, 9, 13, 16, 18, 21, 23, 25, 26, 28, 29, 31, 32, 34, 35],
                [3.1, 9, 16, 18, 21, 24, 26, 28, 30, 32, 34, 36, 37, 39, 40],
                [3.1, 9, 16, 19, 23, 26, 29, 32, 34, 36, 38, 40, 42, 44, 46],
                [3.1, 9, 16, 21, 25, 29, 32, 35, 38, 40, 43, 45, 47, 49, 51],
                [3.1, 9, 16, 25, 27, 31, 35, 38, 41, 44, 47, 50, 52, 54, 56],
                [3.1, 9, 16, 25, 35, 34, 38, 41, 45, 48, 51, 54, 57, 59, 62],
                [3.1, 9, 16, 25, 35, 36, 40, 44, 48, 52, 55, 58, 61, 64, 67],
                [3.1, 9, 16, 25, 35, 38, 43, 47, 52, 55, 59, 62, 66, 69, 72],
                [3.1, 9, 16, 25, 35, 46, 45, 50, 55, 59, 63, 67, 70, 73, 77],
                [3.1, 9, 16, 25, 35, 46, 47, 53, 58, 62, 66, 70, 74, 78, 81],
                [3.1, 9, 16, 25, 35, 46, 58, 55, 60, 65, 70, 74, 78, 82, 86],
                [3.1, 9, 16, 25, 35, 46, 58, 57, 63, 68, 73, 78, 82, 87, 91],
                [3.1, 9, 16, 25, 35, 46, 58, 71, 66, 71, 77, 82, 86, 91, 95],
                [3.1, 9, 16, 25, 35, 46, 58, 71, 85, 99, 80, 85, 90, 95, 100],
                [3.1, 9, 16, 25, 35, 46, 58, 71, 85, 99, 114, 89, 94, 99, 104],
                [3.1, 9, 16, 25, 35, 46, 58, 71, 85, 99, 114, 92, 98, 103, 108],
                [3.1, 9, 16, 25, 35, 46, 58, 71, 85, 99, 114, 130, 147, 164, 182],
                [3.1, 9, 16, 25, 35, 46, 58, 71, 85, 99, 114, 130, 147, 164, 182],
                [3.1, 9, 16, 25, 35, 46, 58, 71, 85, 99, 114, 130, 147, 164, 182],
                [3.1, 9, 16, 25, 35, 46, 58, 71, 85, 99, 114, 130, 147, 164, 182],
                [3.1, 9, 16, 25, 35, 46, 58, 71, 85, 99, 114, 130, 147, 164, 182],
                [3.1, 9, 16, 25, 35, 46, 58, 71, 85, 99, 114, 130, 147, 164, 182],
                [3.1, 9, 16, 25, 35, 46, 58, 71, 85, 99, 114, 130, 147, 164, 182],
                [3.1, 9, 16, 25, 35, 46, 58, 71, 85, 99, 114, 130, 147, 164, 182],
                [3.1, 9, 16, 25, 35, 46, 58, 71, 85, 99, 114, 130, 147, 164, 182],
            ],
            value2: [
                [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                [2.8, 4.2, 5.2, 6.1, 6.9, 7.5, 8.2, 8.7, 9.3, 9.8, 10.3, 10.8, 11.2, 11.6, 12.1],
                [6.3, 7.9, 10.1, 11.9, 13.4, 14.8, 16.1, 17.3, 18.4, 19.4, 20.4, 21.3, 22.2, 23.1, 23.9],
                [6.3, 11, 15, 17, 20, 22, 24, 26, 27, 29, 30, 32, 33, 34, 36],
                [6.3, 14, 19, 22, 26, 29, 31, 34, 36, 38, 40, 42, 44, 46, 47],
                [6.3, 18, 22, 27, 31, 35, 38, 41, 44, 47, 50, 52, 54, 56, 59],
                [6.3, 18, 25, 31, 37, 41, 45, 49, 52, 56, 59, 62, 65, 67, 70],
                [6.3, 18, 33, 35, 42, 47, 52, 56, 60, 64, 68, 71, 75, 78, 81],
                [6.3, 18, 33, 39, 46, 53, 58, 63, 68, 73, 77, 81, 85, 88, 92],
                [6.3, 18, 33, 42, 50, 58, 64, 70, 76, 81, 86, 90, 94, 98, 102],
                [6.3, 18, 33, 50, 54, 63, 70, 77, 83, 89, 94, 99, 104, 109, 113],
                [6.3, 18, 33, 50, 70, 67, 75, 83, 90, 96, 102, 108, 113, 118, 123],
                [6.3, 18, 33, 50, 70, 71, 81, 89, 97, 104, 110, 116, 122, 128, 133],
                [6.3, 18, 33, 50, 70, 75, 85, 95, 103, 111, 118, 125, 131, 137, 143],
                [6.3, 18, 33, 50, 70, 92, 90, 100, 109, 118, 126, 133, 140, 147, 153],
                [6.3, 18, 33, 50, 70, 92, 94, 105, 115, 124, 133, 141, 149, 156, 163],
                [6.3, 18, 33, 50, 70, 92, 116, 110, 121, 131, 140, 149, 157, 165, 172],
                [6.3, 18, 33, 50, 70, 92, 116, 114, 126, 137, 147, 156, 165, 173, 181],
                [6.3, 18, 33, 50, 70, 92, 116, 142, 131, 143, 153, 163, 173, 182, 190],
                [6.3, 18, 33, 50, 70, 92, 116, 142, 169, 198, 160, 170, 181, 190, 199],
                [6.3, 18, 33, 50, 70, 92, 116, 142, 169, 198, 229, 177, 188, 198, 208],
                [6.3, 18, 33, 50, 70, 92, 116, 142, 169, 198, 229, 184, 195, 206, 216],
                [6.3, 18, 33, 50, 70, 92, 116, 142, 169, 198, 229, 260, 294, 328, 364],
                [6.3, 18, 33, 50, 70, 92, 116, 142, 169, 198, 229, 260, 294, 328, 364],
                [6.3, 18, 33, 50, 70, 92, 116, 142, 169, 198, 229, 260, 294, 328, 364],
                [6.3, 18, 33, 50, 70, 92, 116, 142, 169, 198, 229, 260, 294, 328, 364],
                [6.3, 18, 33, 50, 70, 92, 116, 142, 169, 198, 229, 260, 294, 328, 364],
                [6.3, 18, 33, 50, 70, 92, 116, 142, 169, 198, 229, 260, 294, 328, 364],
                [6.3, 18, 33, 50, 70, 92, 116, 142, 169, 198, 229, 260, 294, 328, 364],
                [6.3, 18, 33, 50, 70, 92, 116, 142, 169, 198, 229, 260, 294, 328, 364],
                [6.3, 18, 33, 50, 70, 92, 116, 142, 169, 198, 229, 260, 294, 328, 364],
            ]
        }
    }
]