import { useEffect, useState, Children } from "react";
import PropTypes from 'prop-types';
import { useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TableHead from '@mui/material/TableHead';
import monitorService from "../Shared/MonitorService";
import { format, startOfDay, endOfDay } from 'date-fns';
import { NewExcel } from "../../../../Utils/excel";
import { CalculateVolume } from "../../../Components/Interpolation/volume";
import { CalculateFlow } from "../../../Components/Interpolation/drain";
import { CalculateFlow as CalculateFlowSpin } from "../../../Components/Interpolation/spin";
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

export function getTimestampsInRange(startDateString, endDateString) {
    // Chuyển đổi chuỗi ngày thành đối tượng Date
    const startDate = startOfDay(new Date(startDateString))
    let endDate = endOfDay(new Date(endDateString)).getTime() > Date.now() ? new Date() : endOfDay(new Date(endDateString))
    // Kiểm tra tính hợp lệ của dữ liệu đầu vào
    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime()) || endDate < startDate) {
        return []; // Trả về mảng rỗng nếu dữ liệu không hợp lệ
    }

    const timestamps = [];
    let currentDate = new Date(startDate);

    // Lặp qua từng giờ trong khoảng thời gian
    while (currentDate <= endDate) {
        // Thêm timestamp vào mảng
        timestamps.push(currentDate.getTime());

        // Tăng thời gian lên 1 giờ
        currentDate.setHours(currentDate.getHours() + 1);
    }

    return timestamps.reverse();
}


export default function ListDataAC({ boxSelected, time, boxs, exportExcel, handleExportExcel, handleSearchRecords, searchRecords }) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [records, setRecords] = useState([]);
    const [metrics, setMetrics] = useState([]);

    useEffect(() => {
        if (!searchRecords) return;
        setRecords([]);
        const currentBox = boxs.find(b => b.id === boxSelected);
        // add column V
        if (currentBox.metrics.some(m => m.code === "WAU") && !currentBox.metrics.some(m => m.code === "V")) currentBox.metrics.push({ code: "V", metric: "V", name: "Dung tích hồ" })
        // add column Q
        else if (currentBox.metrics.some(m => m.code === "DR1") && !currentBox.metrics.some(m => m.code === "Q")) currentBox.metrics.push({ code: "Q", metric: "Q", name: "Lưu lượng" })
        else if (currentBox.metrics.some(m => m.code === "RA")) {
            if (!currentBox.metrics.some(m => m.code === "current")) currentBox.metrics.push({ code: "current", metric: "RA", name: "Lượng mưa giờ" })
            if (!currentBox.metrics.some(m => m.code === "total")) currentBox.metrics.push({ code: "total", metric: "RA", name: "Lượng mưa ngày" })
        }
        setMetrics([...currentBox.metrics])
        monitorService.getRecordByBox(boxSelected, time)
            .then(res => {
                const data = res.data;
                if (currentBox.metrics.some(m => m.code === "DR1")) {
                    monitorService.getRecordByBox("LPDNWOUM", time).then(rp => {
                        const waterRecords = rp.data?.records || []
                        if (waterRecords.length > 0) {
                            data.records.map(t => {
                                // show DR in cm
                                // if (t.DR1) {t.DR1 *= 100;}
                                // if (t.DR2) {t.DR2 *= 100;}

                                // data for column Q
                                if (Object.keys(t).includes("DR2")) {
                                    const result = waterRecords.find(w => w.t === t.t)
                                    let num = 2
                                    let val = (t.DR1 + t.DR2) / 2
                                    if ((t.DR1 && !t.DR2) || (t.DR2 && !t.DR1)) {
                                        num = 1
                                        val = t.DR1 ? t.DR1 : t.DR2
                                    }
                                    t.Q = CalculateFlowSpin(num, result.WAU, val, currentBox.group_id)
                                } else if (Object.keys(t).includes("DR1")) {
                                    const result = waterRecords.find(w => w.t === t.t)
                                    t.Q = CalculateFlow(t.DR1 / 100, result.WAU, currentBox.group_id)
                                }
                            });
                            setRecords([...data.records])
                            handleSearchRecords(false)
                        }
                    }).catch(e => {
                        console.log(e)
                        setRecords([...data.records])
                        handleSearchRecords(false)
                    })
                } else if (currentBox.metrics.some(m => m.code === "WAU")) {
                    // data for column V
                    data.records.map(t => {
                        if (t.WAU) t.V = CalculateVolume(t.WAU, currentBox.group_id)
                    });
                    setRecords([...data.records])
                    handleSearchRecords(false)
                } else if (currentBox.metrics.some(m => m.code === "RA")) {
                    let totalRA = 0
                    let currentRA = 0
                    data.records.map(t => {
                        const time = new Date(t.t * 1000)
                        const min = time.getMinutes()
                        if(min === 55) {
                            currentRA= t.RA
                        }
                        t.current = currentRA
                    })
                    data.records.reverse()
                    // total RA
                    data.records.map(t => {
                        const time = new Date(t.t * 1000)
                        const min = time.getMinutes()
                        if(min === 55) {
                            totalRA += +t.RA
                        }
                        t.total = totalRA
                    });
                    data.records.reverse()
                    setRecords([...data.records])
                    handleSearchRecords(false)
                }
            })
            .catch(err => console.log(err));
    }, [searchRecords]);

    useEffect(async () => {
        if (!exportExcel || records.length === 0 || metrics.length === 0) return;
        await NewExcel(records, metrics);
        console.log('xuất báo cáo');
        handleExportExcel(false);
    }, [exportExcel])

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - records.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div className="ListDataKG">
            {searchRecords ? <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                <LinearProgress color="secondary" />
                <LinearProgress color="success" />
                <LinearProgress color="inherit" />
            </Stack>
                :
                <TableContainer className="data-table" component={Paper}>
                    <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell style={{ textAlign: 'center', width: '40%' }} align="right">Thời gian</StyledTableCell>
                                {metrics.map(m => {
                                    return <StyledTableCell key={m.code} align="center">{m.name}{m.metric === 'RA' ? '(mm)' : m.metric === "V" ? "(triệu m3)" : m.metric === "Q" ? "(m3/s)" : '(cm)'}</StyledTableCell>
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0
                                ? records.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : records
                            ).map((row) => (
                                <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <StyledTableCell style={{ textAlign: 'center', width: '40%' }} component="th" scope="row">
                                        {row.t && format(row.t * 10e2, "dd/MM/yyyy - HH:mm")}
                                    </StyledTableCell>
                                    {metrics.map(m => {
                                        return <StyledTableCell style={{}} align="center">
                                            {row[m.code]}
                                        </StyledTableCell>
                                    })}
                                </TableRow>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={metrics.length + 1} />
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                    colSpan={metrics.length + 1}
                                    count={records.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                            'aria-label': 'rows per page',
                                        },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            }
        </div>
    );
}
