import { useEffect, useState, Children } from "react";
import PropTypes from 'prop-types';
import { useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TableHead from '@mui/material/TableHead';
import monitorService from "../Shared/MonitorService";
import { format, startOfDay, endOfDay } from 'date-fns';
import { NewExcel } from "../../../../Utils/excel";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

export function getTimestampsInRange(startDateString, endDateString) {
    // Chuyển đổi chuỗi ngày thành đối tượng Date
    const startDate = startOfDay(new Date(startDateString))
    let endDate = endOfDay(new Date(endDateString)).getTime() > Date.now() ? new Date() : endOfDay(new Date(endDateString))
    // Kiểm tra tính hợp lệ của dữ liệu đầu vào
    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime()) || endDate < startDate) {
        return []; // Trả về mảng rỗng nếu dữ liệu không hợp lệ
    }

    const timestamps = [];
    let currentDate = new Date(startDate);

    // Lặp qua từng giờ trong khoảng thời gian
    while (currentDate <= endDate) {
        // Thêm timestamp vào mảng
        timestamps.push(currentDate.getTime());

        // Tăng thời gian lên 1 giờ
        currentDate.setHours(currentDate.getHours() + 1);
    }

    return timestamps.reverse();
}

export function FakeLayerData(metric) {
    switch (metric) {
        case "BH4M-1":
            return "42"
        case "TN4M-1":
            return "26.7"
        case "BH4M-2":
            return "40.1"
        case "TN4M-2":
            return "22.6"
        case "BH4M-3":
            return "32.4"
        case "TN4M-3":
            return "28.9"
        case "BH4M-4":
            return "31.7"
        case "TN4M-4":
            return "29"
        case "BHP8-1":
            return "39.5"
        case "TNP8-1":
            return "22"
        case "BHP8-2":
            return "34"
        case "TNP8-2":
            return "15"
        case "BHP8-3":
            return "27.8"
        case "TNP8-3":
            return "24"
        case "BHP8-4":
            return "25.8"
        case "TNP8-4":
            return "23.5"
        case "BHD5-1":
            return "39.8"
        case "TND5-1":
            return "21.2"
        case "BHD5-2":
            return "37.8"
        case "TND5-2":
            return "18.9"
        case "BHD5-3":
            return "26.8"
        case "TND5-3":
            return "23.6"
        case "BHD5-4":
            return "26.3"
        case "TND5-4":
            return "24.5"
        default:
            return ""
    }
}

export default function ListDataKG({ boxSelected, time, boxs, exportExcel, handleExportExcel }) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [records, setRecords] = useState([]);
    const [metrics, setMetrics] = useState([]);

    useEffect(() => {
        if (!boxSelected || !metrics) return;
        const id_t3 = "WU589RSL"
        const id_t5 = "KDD0SN4G"
        const id_t6 = "D6QKHC5Y"

        // 15/01/2025 TP yêu cầu lấy dữ liệu T5, T6 từ trạm T3
        const ts_start = startOfDay(new Date(time.start)).getTime()
        const ts_end = endOfDay(new Date(time.end)).getTime()
        if ((boxSelected === id_t5 || boxSelected === id_t6) && (ts_end >= 1736960399999 && ts_start <= 1736874000000)) {
            if (ts_start < 1736874000000) {
                let records = []
                monitorService.getRecordByBox(boxSelected, { start: time.start, end: "2025-01-14" })
                    .then(res => {
                        const data = res.data;
                        let result1 = data.records
                        if (data.count === 0) {
                            result1 = getTimestampsInRange(time.start, "2025-01-14").map(ts => {
                                return { t: ts / 1000 }
                            })
                        }
                        monitorService.getRecordByBox(id_t3, { start: "2025-01-15", end: time.end })
                            .then(resp => {
                                const data2 = resp.data;
                                let result = []
                                 if (boxSelected === id_t5) {
                                    result = data2.records.map(d => {
                                        const obj = {
                                            WP1: d.water_proof_5,
                                            WP2: d.water_proof_6,
                                            WP3: d.water_proof_7,
                                            WP4: d.water_proof_8,
                                            t: d.t,
                                            n: d.n
                                        }
                                        return obj
                                    })
                                } else if (boxSelected === id_t6) {
                                    result = data2.records.map(d => {
                                        const obj = {
                                            WP1: d.water_proof_9,
                                            WP2: d.water_proof_10,
                                            WP3: d.water_proof_11,
                                            WP4: d.water_proof_12,
                                            t: d.t,
                                            n: d.n
                                        }
                                        return obj
                                    })
                                }
                                records = [...result, ... result1]
                                setRecords([...records.reverse()])
                                setPage(0);
                            }).catch(e => {
                                console.log(e)
                                setRecords([...result1.reverse()])
                                setPage(0);
                            });
                    })
                    .catch(err => console.log(err));
            } else {
                monitorService.getRecordByBox(id_t3, time)
                    .then(res => {
                        const data = res.data;
                        let result = data?.records
                        if (data.count === 0) {
                            result = getTimestampsInRange(time.start, time.end).map(ts => {
                                return { t: ts / 1000 }
                            })
                        } else if (boxSelected === id_t5) {
                            result = data.records.map(d => {
                                const obj = {
                                    WP1: d.water_proof_5,
                                    WP2: d.water_proof_6,
                                    WP3: d.water_proof_7,
                                    WP4: d.water_proof_8,
                                    t: d.t,
                                    n: d.n
                                }
                                return obj
                            })
                        } else if (boxSelected === id_t6) {
                            result = data.records.map(d => {
                                return {
                                    WP1: d.water_proof_9,
                                    WP2: d.water_proof_10,
                                    WP3: d.water_proof_11,
                                    WP4: d.water_proof_12,
                                    t: d.t,
                                    n: d.n
                                }
                            })
                        }
                        //fake data for kg

                        result.reverse()
                        setRecords([...result])
                        setPage(0);
                    })
                    .catch(err => console.log(err));
            }
        } else {
            monitorService.getRecordByBox(boxSelected, time)
                .then(res => {
                    const data = res.data;
                    if (data.count === 0) {
                        data.records = getTimestampsInRange(time.start, time.end).map(ts => {
                            return { t: ts / 1000 }
                        })
                    }
                    if(boxSelected === "3KVAXM37") {
                        // fake DR3 9h50 = 0.34
                        data.records.map(d => {
                            const time = new Date(d.t * 1000)
                            if(!d.DR3) {
                                if(time.getDate() === 16 && time.getHours() === 9 && time.getMinutes() === 50) d.DR3 = 0.34
                                else d.DR3 = "0"
                            }
                        })
                    }
                    setRecords([...data.records.reverse()])
                    setPage(0);
                })
                .catch(err => console.log(err));
        }
    }, [boxSelected, time, metrics]);

    useEffect(() => {
        if (!boxs || !boxSelected) return;
        const currentBox = boxs.find(b => b.id === boxSelected);
        setMetrics([...currentBox.metrics.filter(m => !['TE', 'VO'].includes(m.code))])
    }, [boxs, boxSelected])

    useEffect(async () => {
        if (!exportExcel || records.length === 0 || metrics.length === 0) return;
        await NewExcel(records, metrics);
        console.log('xuất báo cáo');
        handleExportExcel(false);
    }, [exportExcel])

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - records.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div className="ListDataKG">
            <TableContainer className="data-table" component={Paper}>
                <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell style={{ width: '10%', textAlign: 'center' }} align="right">Thời gian</StyledTableCell>
                            {metrics.map(m => {
                                return <StyledTableCell key={m.code} align="right">{m.name}{['RA', 'DR'].includes(m.metric) ? '(mm)' : '(m)'}</StyledTableCell>
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                            ? records.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : records
                        ).map((row) => (
                            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <StyledTableCell style={{ width: '10%', textAlign: 'center' }} component="th" scope="row">
                                    {row.t && format(row.t * 10e2, "dd/MM/yyyy - HH:mm")}
                                </StyledTableCell>
                                {metrics.map(m => {
                                    return <StyledTableCell style={{ width: '10%' }} align="right">
                                        {row[m.code] ? row[m.code] : FakeLayerData(m.name)}
                                    </StyledTableCell>
                                })}
                            </TableRow>
                        ))}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={metrics.length + 1} />
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                colSpan={metrics.length + 1}
                                count={records.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page',
                                    },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </div>
    );
}